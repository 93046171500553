<template>
	<div
		v-if="isBlockShown"
		id="programm_announces"
		:class="'panel programms ' +
			(isHeaderIndent? ' mt-0 pt-0 ' : '') +
			(isFooterIndent? ' mb-0 pb-0 ' : '') +
			block.appearance"
	>
		<div>
			<div class="container">
				<div v-if="isHeaderVisible" :class="'panel_header' + (isBrand ? ' sk' : '')">
					<div class="row align-items-end">
						<div class="col-12">
							<h2>{{ block.block_title }}</h2>
							<p v-if="block.description">
								{{ block.description }}
							</p>
						</div>
					</div>
				</div>

				<ul class="programms_list row">
					<li v-for="(item, index) in block.events" :key="index" class="col-12 col-md-6">
						<div
							v-if="item.listing_cover_image_default.length !== 0 || item.top_cover_image_default.length !== 0"
							class="programm"
						>
							<div class="mb-0">
								<div
									v-if="item.listing_cover_image_default.length !== 0"
									class="embed-responsive embed-responsive-21by9"
								>
									<img
										:src="item.listing_cover_image_default.src"
										class="embed-responsive-item"
										:alt="item.listing_cover_image_default.alt"
									>
								</div>
								<div
									v-else
									class="embed-responsive embed-responsive-21by9"
								>
									<img
										:src="item.top_cover_image_default.src"
										class="embed-responsive-item"
										:alt="item.top_cover_image_default.alt"
									>
								</div>
							</div>
							<div class="programm_content">
								<div>
									<div class="programm_header">
										<span class="programm_type">{{ item.type_titles.title_default }}</span>
									</div>

									<div
										v-if="block.show_alumni_link && !isAlumni"
										class="title"
									>
										{{ item.title }}
									</div>

									<nuxt-link
										v-else
										:to="$url.event(item.slug, item.typology_slug)"
										class="title"
									>
										{{ item.title }}
									</nuxt-link>


									<p v-if="item.subtitle" class="description" v-html="item.subtitle" />
								</div>

								<div class="programm_content_footer">

									<div
										v-if="block.show_alumni_link && !isAlumni"
									>
									</div>

									<nuxt-link
										v-else
										:to="$url.event(item.slug, item.typology_slug)"
										class="more_link"
									>
										{{ $t('more') }}
									</nuxt-link>


									<div class="programm_content_footer_reminder">
										{{ item.event_date | moment("DD") }} {{ item.event_date | moment("MMMM") }}
										{{ item.event_date | moment("YYYY") }}
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>

				<div v-if="isFooterVisible" class="panel_footer" v-html="block.footer" />
			</div>
		</div>
		<component :is="'script'" type="application/ld+json">
			{{ jsonld }}
		</component>
	</div>
</template>
<script>
import '@/utils/moment';

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

import { TYPOLOGY } from '@/utils/dictionaries';
import { mapActions } from 'vuex';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';

export default {
	name: 'PanelProgramms',
	components: {
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	mixins: [checkIsBlockShown],
	props: {
		block: { type: Object, required: true },
		fetchedData: { type: Object, required: true },
	},
	data() {
		return {
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				// freeMode: true,
				loop: false,
			},
			TYPOLOGY,
		};
	},
	computed: {
		jsonld() {
			const schema = {
				'@context': 'https://schema.org',
				'@type': 'ItemList',
				itemListElement: this.block.events.map((el, index) => ({
					'@type': 'ListItem',
					position: index + 1,
					url: el.frontend_url,
				})),
			};
			return schema;
		},
		isHeaderVisible() {
			return this.block.display_header === 1;
		},
		isHeaderIndent() {
			return this.block.hide_top_indent === 1;
		},
		isFooterVisible() {
			return this.block.display_footer === 1;
		},
		isFooterIndent() {
			return this.block.hide_bottom_indent === 1;
		},
		isBrand() {
			return this.block.display_brand_background === 1;
		},
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
	},
};

</script>
<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-programms";
</style>
